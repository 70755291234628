.container {
	background-color: transparent;
	width: 100%;

	.head {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 50px;

		//Mobile
		@media (max-width: 1200px) {
			// padding: 24px;
			width: 100%;
		}

		@media (max-width: 674px) {
			flex-direction: column;
			gap: 20px;
		}

		.price {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				color: #646463;
				font-size: 20px;
				line-height: 140%;
				font-weight: 500;
				text-align: center;
				margin-bottom: 4px;

				@media (min-width: 1200px) and (max-width: 1440px) {
					font-size: 16px;
					font-weight: 600;
					line-height: 120%;
				}
			}

			.subtitle {
				color: #222221;
				font-size: 28px;
				line-height: 120%;
				font-weight: 600;
				text-align: center;

				@media (min-width: 1200px) and (max-width: 1440px) {
					font-size: 22px;
					font-weight: 600;
					line-height: 100%;
				}
			}
		}

		.arrow {
			padding: 0 40px;

			@media (max-width: 674px) {
				rotate: 90deg;
			}
		}
	}

	.cards {
		display: flex;
		justify-content: space-between;
		width: 100%;

		//Mobile
		@media (max-width: 880px) {
			flex-direction: column;
			align-items: center;
			justify-content: stretch;
			gap: 30px;
		}

		div:nth-child(2) {
			margin: 0 20px;
			//Mobile
			@media (max-width: 1200px) {
				margin: 0 20px;
			}
		}
	}

	.customAmount {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;

	label {
		font-weight: 500;
		color: #646463;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		margin-bottom: 4px;

		@media (min-width: 1200px) and (max-width: 1440px) {
			font-size: 16px;
			font-weight: 600;
			line-height: 120%;
		}
	}

	input {
		width: 100%;
		padding: 10px 75px 10px 10px;
		font-size: 16px;
		border-radius: 6px;
		box-sizing: border-box;
		border: 3px solid rgb(189, 198, 241);
	}

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input:focus {
		outline: none;
		border-color: #007bff; /* Highlight color on focus */
		box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
	}

	@media (min-width: 768px) {
		input {
			max-width: 300px; /* Adjust as needed */
		}
	}

		.usdtContainer {
			position: absolute;
			right: 10px; /* Adjust to position the logo correctly */
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			font-weight: bold;

			.usdPrice {
				height: 16px;

				@media (min-width: 1200px) and (max-width: 1440px) {
					height: 10px;
				}
			}
		}
	}

	.error {
		text-align: center;
		color: #ff3b3b;
		margin-top: 16px;
		padding: 20px;
		box-sizing: border-box;
		background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 0.9),
				rgba(255, 255, 255, 0.9)
			),
			#ff3b3b;

		border: 1px dashed #ff3b3b;
		border-radius: 8px;
	}
}
