@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer components {
	.golden-gradient-bg {
		background-image: linear-gradient(
			97.37deg,
			#f2c94c -24.9%,
			#ff9736 54.59%,
			#d02bc0 128.12%
		);
		background-repeat: no-repeat;
		background-size: 120% 120%;
		background-position: center;
	}

	.ping {
		animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;

		@keyframes ping {
			75%,
			100% {
				transform: scale(2);
				opacity: 0;
			}
		}
	}

	.custom-scroll {
		// @apply scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#909294]/80 scrollbar-track-rounded-[16px] scrollbar-thumb-rounded-[16px] scrollbar-w-[4px];
	}
}
.spaace-txt-color {
	background: linear-gradient(270deg, #ff421b 0%, #ff9933 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
