.container {
	width: 100%;
	background-color: #f9fafe;
	border-radius: 8px;
	border: 1px solid #e9e9e9;
	padding: 40px 40px 40px 64px;
	box-sizing: border-box;
	transform: translateX(-20px);

	//Mobile
	@media (max-width: 1200px) {
		width: 100%;
		padding: 20px 20px 20px 32px;
		transform: translateX(0px);
	}

	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		color: #ff7225;
	}

	.content {
		& > * {
			padding: 24px 0;
		}

		& > *:not(:last-child) {
			border-bottom: 1px solid #dee1f8;
		}

		.summary {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			color: #646463;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 140%;

			@media (min-width: 1200px) and (max-width: 1440px) {
				font-size: 16px;
			}
		}
	}

	.countdown {
		background: #fef0e9;
		padding: 14px 0;
		border-radius: 8px;
		border: 1px dashed #ff7225;

		& > p {
			text-align: center;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			color: #ff7225;

			@media (min-width: 1200px) and (max-width: 1440px) {
				font-size: 12px;
				font-weight: 600;
				line-height: 100%;
			}
		}

		& > div > div > span {
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 120%;
		}

		// This is the number of days/hours/minutes/seconds left from the countdown component
		& > div > div > div > p {
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 120%;
			color: #222221;

			@media (min-width: 1200px) and (max-width: 1260px) {
				font-size: 14px;
				font-weight: 600;
				line-height: 100%;
			}

			@media (min-width: 1260px) and (max-width: 1440px) {
				font-size: 18px;
				font-weight: 600;
				line-height: 100%;
			}
		}

		& > div > div > div > span {
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 120%;

			@media (min-width: 1200px) and (max-width: 1260px) {
				font-size: 8px;
				font-weight: 600;
				line-height: 100%;
			}

			@media (min-width: 1260px) and (max-width: 1440px) {
				font-size: 10px;
				font-weight: 600;
				line-height: 100%;
			}
		}
	}
}
