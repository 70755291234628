// Modal
// ––––––––––––––––––––––

.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	background: #00000038;

	&-container {
		background: white;
		border-radius: 20px;
		border-radius: 15px;
		padding: 35px 25px;
		margin: auto;
		width: auto;
		max-width: 500px;

		@media screen and (max-width: 500px) {
			width: 90%;
			max-width: 90%;
		}
	}

	&-header {
		display: flex;
		justify-content: flex-end;
		font-size: 25px;
	}

	&-title {
		text-align: center;
		font-size: 22px;
		font-weight: 700;
		line-height: 18px;
		margin: 0;
	}

	&-body {
		padding-top: 20px;
	}
}
