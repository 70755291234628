.container {
	position: relative;
	border: 3px solid rgb(189, 198, 241);
	border-radius: 8px;

	min-width: 180px;
	min-height: 150px;

	padding-bottom: 25px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	background-color: rgb(239, 241, 251);

	transition: all 0.3s ease;

	@media (min-width: 1200px) and (max-width: 1440px) {
		min-width: 120px;
		min-height: 126px;
	}

	@media (max-width: 1200px) {
		width: 100%;
		max-width: 400px;
	}

	&:hover {
		transform: scale(1.02);
		box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
		cursor: pointer;
	}

	&.active {
		border: 3px solid #5a70db;
		transition: border-color 0.3s ease;
	}

	&.maxLevel {
		background-image: url("../../../assets/icons/badges/max-level-background.svg");
		background-repeat: no-repeat;
		background-size: cover;
	}

	.checkMark {
		position: absolute;
		top: -12px;
		right: -12px;
		width: 28px;
		z-index: 10000;
		background-color: transparent;
	}

	.badge {
		margin: -40px auto 0;
		transition: all 0.3s ease;
		user-select: none;

		@media (min-width: 1200px) and (max-width: 1440px) {
			scale: 0.7;
		}
	}

	.badgeHover {
		animation: rotation 0.7s;
	}

	.prices {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.price {
			font-family: Golos Text, sans-serif;
			font-size: 20px;
			font-weight: 600;
			line-height: 28px;
			letter-spacing: 0;
			text-align: center;

			@media (min-width: 1200px) and (max-width: 1440px) {
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
			}
		}

		.usdtContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			font-weight: bold;

			.usdPrice {
				height: 16px;

				@media (min-width: 1200px) and (max-width: 1440px) {
					height: 10px;
				}
			}
		}
	}

}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
