.postTransactionPanel {
	z-index: 10000;
	padding: 40px 20px;
	box-sizing: border-box;
	width: 100%;
	min-width: calc(186px * 3 + 20px * 2);
	height: fit-content;
	//Mobile
	@media (max-width: 768px) {
		min-width: fit-content;
	}

	.titles {
		display: flex;
		flex-direction: column;
		.title {
			white-space: wrap;
			width: 100%;
			//Mobile
			@media (max-width: 768px) {
				font-size: 22px;
			}
		}
		.subtitle {
			white-space: wrap;
			width: 100%;
			opacity: 0.6;
			//Mobile
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
	}

	.loader {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 40px 0 20px;
		position: relative;
		min-height: 136px;
	}

	.amount {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		button {
			width: 100%;
		}
	}
}
