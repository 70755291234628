.container {
	background-color: transparent;
	width: 100%;
	text-align: center;

	.head {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 40px;

		//Mobile
		@media (max-width: 1200px) {
			// padding: 24px;
			width: 100%;
		}

		@media (max-width: 674px) {
			flex-direction: column;
			gap: 20px;
		}

		.price {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				color: #646463;
				font-size: 20px;
				line-height: 140%;
				font-weight: 500;
				text-align: center;
				margin-bottom: 4px;

				@media (min-width: 1200px) and (max-width: 1440px) {
					font-size: 16px;
					font-weight: 600;
					line-height: 120%;
				}
			}

			.subtitle {
				color: #222221;
				font-size: 28px;
				line-height: 120%;
				font-weight: 600;
				text-align: center;

				@media (min-width: 1200px) and (max-width: 1440px) {
					font-size: 22px;
					font-weight: 600;
					line-height: 100%;
				}
			}
		}

		.arrow {
			padding: 0 40px;

			@media (max-width: 674px) {
				rotate: 90deg;
			}
		}
	}

	.subtitle {
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		text-align: center;
		color: #222221;
		margin: 20px 0;

		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}


	.imageWrapper {
		width: 40%;
		margin: 0 auto;
		position: relative;
		padding-bottom: 40%;
		background: #f5f5f5; /* Placeholder background color */

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.qrCode {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
