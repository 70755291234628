.container {
	position: relative;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;

	.logo {
		margin-top: 30px;
		margin-bottom: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: calc((5 / 6) * 100%);

		@media (max-width: 768px) {
		}
	}

	.cardsContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		.card {
			width: 100%;
			transition: transform 1000ms cubic-bezier(0.4, 0, 0.2, 1) 700ms,
				opacity 1000ms cubic-bezier(0.4, 0, 0.2, 1) 700ms;
			display: flex;
			align-items: center;
			justify-content: center;

			&:nth-child(2) {
				@media (max-width: 1200px) {
					order: -1;
				}
			}
		}

		@media (max-width: 1200px) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 10px;
		}
	}

	.bottomPanel {
		margin-top: 48px;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;

		.title {
			font-size: 18px;
			font-weight: 600;
			color: #222221;
			text-align: center;
			margin-bottom: 8px;
		}
		.progressBar {
			margin-top: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			.progress {
				position: relative;
				height: 24px;
				width: 66%;
				border-radius: 10px;
				background-color: #f5f4f4;
				overflow: visible;

				//Mobile
				@media (max-width: 768px) {
					width: 100%;
				}

				.bar {
					width: 100%;
					height: 100%;
					position: relative;
					border-radius: 10px;
					padding: 0 16px;
					box-sizing: border-box;
					display: flex;
					align-items: center;

					.percent {
						font-size: 18px;
						font-weight: 600;
						color: #ffffff;
						text-align: center;
						font-size: 12px;
						font-weight: 600;
					}

					.icon {
						position: absolute;
						top: 50%;
						right: -10px;
						transform: translateY(-50%);
						height: 42px;
						width: 42px;
						z-index: 100;
					}
				}
			}
		}
	}
}

.mainPanel {
	position: relative;
	background-color: #f9fafe;
	border-radius: 8px;
	border: 1px solid #e9e9e9;
	padding: 48px;
	box-sizing: border-box;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.03);

	//Mobile
	@media (max-width: 1200px) {
		padding: 24px;
		width: 100%;
	}

	@media (max-width: 1440px) {
		&.thanksOpen {
			height: 600px;
			overflow: hidden;
		}
	}
}
