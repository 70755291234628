.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	display: flex;
	justify-content: flex-end;
	padding: 40px;
	box-sizing: border-box;
	z-index: 100000;
	gap: 10px;

	@media (max-width: 768px) {
		position: sticky;
		justify-content: center;
		padding: 20px;
		align-items: center;
	}
}
