.container {
	min-height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.bgGradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.main {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
	}
}
