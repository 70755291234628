// Button
// ––––––––––––––––––––––

.btn {
	display: flex;
	align-items: center;
	border-radius: 8px;
	font-weight: 700;
	white-space: nowrap;
	justify-content: space-between;

	&:active {
		outline: none;
		transform: translateY(2px);
		transition: transform 1s ease-in-out;
	}

	&-primary {
		color: #000000;
		text-transform: uppercase;
		background-size: 200% 100%;
		transition: background 0.4s ease-in-out;
		background-image: linear-gradient(
			96.64deg,
			#ffaf20 1.35%,
			#ff9000 26%,
			#f4448c 50%,
			#ff9000 74%,
			#ffaf20 98.65%
		);

		&:hover {
			background-position: 200% 0;
			transition: background 0.4s ease-in-out;

			font-family: "Golos Text", sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 160%;
			color: #646463;
		}

		&:disabled {
			background: #949ea4;
		}
	}

	&-secondary {
		border: 1px solid #ff9933;
		color: #ff9933;
		background: transparent;
		border-radius: 32px;
		width: 175px;
		transition: transform 0.1s ease-in-out, background-color 0.2s ease-in-out;

		&:disabled {
			pointer-events: none;
		}

		&:hover {
			transform: scale(1.02);
			background-color: rgba(255, 153, 51, 0.1);
			transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
		}
	}

	&-tertiary {
		border: 1px solid rgb(233, 233, 233);
		color: rgb(100, 100, 100);
		background: rgba(255, 255, 255, 0.6);
		border-radius: 30px;
		width: 175px;

		&:disabled {
			pointer-events: none;
		}

		&:hover {
			transform: scale(1.05);
			animation: transform 0.5s ease-in-out;
		}
	}

	&-base {
	}

	&._xs {
		padding: 8px 12px;
		font-size: 10px;
		line-height: 11px;
		font-weight: 600;

		@media (min-width: 1024px) {
			padding: 8px 16px;
			font-size: 12px;
			line-height: 20px;
			font-weight: 700;
		}

		@media (min-width: 1200px) {
			padding: 8px 24px;
			font-size: 16px;
			line-height: 24px;
			font-weight: 700;
		}
	}

	&._sm {
		padding: 16px 32px;
		font-size: 16px;
		line-height: 16px;
	}

	&._md {
		padding: 12px 24px;
		font-size: 16px;
		line-height: 24px;
	}

	&._lg {
		padding: 16px 32px;
		font-size: 16px;
		line-height: 16px;

		@media (min-width: 1024px) {
			padding: 24px 72px;
			font-size: 20px;
			line-height: 24px;
		}
	}

	&._notxt {
		padding: 8px;
	}

	& > .icon {
		display: flex;
		align-items: center;
		justify-items: flex-end;

		& + .label {
			@media (min-width: 1024px) {
				margin-left: 8px;
			}
		}

		& > .separator {
			border-right: 1px solid #000;
			margin-right: 16px;
			height: 20px;
		}
	}
}
