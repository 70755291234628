.saleStages {
	height: fit-content;
	width: 100%;
	min-width: 400px;
	background-color: #f9fafe;
	border-radius: 8px;
	border: 1px solid #e9e9e9;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	transform: translateX(20px);

	//Mobile
	@media (max-width: 1200px) {
		width: 100%;
		min-width: 300px;
		transform: translateX(0px);
	}

	.stage {
		box-sizing: border-box;
		width: 100%;
		height: fit-content;
		display: flex;
		&:nth-last-child(1) {
			.leftPart {
				.line {
					bottom: unset;
					top: 0;
					height: 50%;
					background: linear-gradient(
						to top,
						#f9fafe 0%,
						#f9fafe 18px,
						#222221 18px
					);
				}
			}
			.rightPart {
				border-bottom: none;
				padding: 20px 0px 40px 0;
				//Mobile
				@media (max-width: 1200px) {
					padding: 10px 0px 20px 0;
				}
			}
		}
		&:nth-child(1) {
			.leftPart {
				.line {
					height: 50%;
					background: linear-gradient(
						to bottom,
						#f9fafe 0%,
						#f9fafe 18px,
						#ff7225 18px
					);
				}
			}
			.rightPart {
				padding: 40px 0px 20px 0;
				//Mobile
				@media (max-width: 1200px) {
					padding: 20px 0px 10px 0;
				}
			}
		}

		.leftPart {
			width: 108px;
			position: relative;

			.circle {
				position: absolute;
				left: calc(50% - 10px);
				top: calc(50% - 10px);
				width: 20px;
				padding: 3px;
				height: 20px;
				border-radius: 10px;
				z-index: 10;
				background-color: transparent;
				border: 2px solid #222221;
			}

			.line {
				position: absolute;
				left: calc(50% - 1px);
				bottom: 0;
				width: 2px;
				height: 100%;
				background: linear-gradient(
					to bottom,
					#222221 0%,
					#222221 calc(50% - 18px),
					#f9fafe calc(50% - 18px),
					#f9fafe calc(50% + 18px),
					#222221 calc(50% + 18px)
				);
			}
		}

		.rightPart {
			padding: 20px 0px;
			margin-right: 40px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			width: calc(100% - 108px - 40px);
			border-bottom: 1px solid #dee2f8;
			.title {
				text-transform: uppercase;
				color: #a7a7a6;
				font-size: 14px;
				font-weight: 600;
				line-height: 140%;
				position: relative;
				width: fit-content;

				.line {
					position: absolute;
					top: calc(50% - 1px);
					left: 0;
					width: 100%;
					height: 2px;
					background-color: #ff7225;
				}
			}
			.subtitle {
				font-size: 24px;
				font-weight: 600;
				line-height: 140%;
				position: relative;
				width: fit-content;

				.line {
					position: absolute;
					top: calc(50% - 1px);
					left: 0;
					width: 100%;
					height: 2px;
					background-color: #ff7225;
				}
			}
		}
	}

	.stageFinished {
		.leftPart {
			.circle {
				background-color: #fadfd3;
				border: 2px solid #ff7225;
			}

			.line {
				position: absolute;
				left: calc(50% - 1px);
				bottom: 0;
				width: 2px;
				height: 100%;
				background: linear-gradient(
					to bottom,
					#ff7225 0%,
					#ff7225 calc(50% - 18px),
					#f9fafe calc(50% - 18px),
					#f9fafe calc(50% + 18px),
					#ff7225 calc(50% + 18px)
				);
			}
		}

		.rightPart {
			.subtitle {
				color: #a7a7a6;
			}
		}
	}

	.stageCurrently {
		.leftPart {
			.circle {
				background-color: transparent;
				border: 2px solid #5dcd7b;
				box-shadow: 0px 0px 20px #5dcd7b;
			}

			.line {
				position: absolute;
				left: calc(50% - 1px);
				bottom: 0;
				height: 100%;
				background: linear-gradient(
					to bottom,
					#ff7225 0%,
					#ff7225 calc(50% - 18px),
					#f9fafe calc(50% - 18px),
					#f9fafe calc(50% + 18px),
					#222221 calc(50% + 18px)
				);
			}
		}

		.rightPart {
			.subtitle {
				color: #5dcd7b;
			}
		}
	}
}
