.container {
	border-top: 1px solid #e9e9e9;
	padding: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-height: 100px;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
	}

	.copyright {
		color: #a7a7a5;
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
	}

	.informations {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 40px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			gap: 20px;
			padding: 10px;
		}

		.link {
			color: #646463;
			font-weight: 400;
			font-size: 16px;
			line-height: 160%;
			text-decoration: underline;
			text-decoration-color: #a7a7a5;
			text-underline-offset: 4px;

			@media screen and (min-width: 768px) and (max-width: 1200px) {
				font-size: 12px;
			}
		}
	}
}
