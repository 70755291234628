.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	padding: 10px;

	@media screen and (max-width: 768px) {
		padding: 10px 20px;
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 700px;

		background-image: url("../../assets/images/background.png");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		z-index: -1;
	}

	.title {
		font-weight: 600;
		font-size: 62px;
		line-height: 120%;
		text-align: center;

		@media screen and (max-width: 768px) {
			font-size: 36px;
		}

		.rainbow {
			background: linear-gradient(to right, #ff9933, #ff421b);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
		}
	}

	.subtitle {
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		text-align: center;
		color: #222221;
		margin-top: 20px;

		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}

	.countdown {
		font-style: normal;
		font-weight: 600;
		font-size: 50px;
		color: #222221;
		position: relative;

		@media screen and (max-width: 768px) {
			font-size: 30px;
		}

		.leftStars {
			position: absolute;
			top: -35px;
			left: -35px;
			animation: rotation 1.5s linear infinite;
		}

		.rightStars {
			position: absolute;
			bottom: -25px;
			right: -25px;
			animation: rotation 1.5s linear infinite;
		}
	}

	.connectButton {
		width: 80%;
		max-width: 400px;
		padding: 22px;
		text-align: center;
		color: white;
		background-color: #ff7225;
		font-weight: 500;
		font-size: 20px;
		line-height: 140%;
		border-radius: 40px;
		transition: background-color 0.15s ease-in-out;

		&:hover {
			background-color: #d05e2a;
		}

		@media screen and (max-width: 768px) {
			width: 50%;
			padding: 12px 15px;
			font-size: 14px;
		}
	}

	.spacer80 {
		flex: 80;
	}

	.spacer120 {
		flex: 120;
	}

	.spacer180 {
		flex: 180;
	}

	.spacer190 {
		flex: 190;
	}

	.qrCode {
		width: 40%;
		max-width: 250px;

		@media screen and (max-width: 768px) {
			display: none;

			+ .spacer80 {
				display: none;
			}
		}
	}
}

@keyframes rotation {
	0%,
	100% {
		transform: rotate(-15deg);
		opacity: 0.5;
	}
	50% {
		transform: rotate(15deg);
		opacity: 1;
	}
	25%,
	75% {
		transform: rotate(0deg);
		opacity: 1;
	}
}
